<div class="sideBarMain">
    <img class="logoImg" alt="" src="../../assets/images/logo-img.png">
    <ul>
        <li (click)="setHeader('Create New Product')"> <a routerLink="/datasheet" routerLinkActive="activeMain"><span>
                    <img src="../../assets/images/list-icon.png"></span>Datasheet </a> </li>
        <li (click)="setHeader('All Datasheets')"> <a routerLink="/all-datasheet" routerLinkActive="activeMain"><span>
                    <img src="../../assets/images/list-new-icon.png"></span>All Datasheets </a> </li>
        <li *ngIf="userType === 'admin' ||  userType === 'superadmin' " (click)="setHeader('Users')"> <a
                routerLink="/users" routerLinkActive="activeMain"><span>
                    <img src="../../assets/images/profile-icon.png"></span>Users </a> </li>
        <!-- <li (click)="setHeader('Ref Code')"> <a routerLink="/refCode" routerLinkActive="activeMain"><span>
                    <img src="../../assets/images/settings-icons.png"></span>Ref Code </a> </li> -->
    </ul>
</div>
<app-header [headerValue]="isSelect"></app-header>