export const environment = {
  production: false,
  apiUrl: 'https://lpa-api.techlabz.in/graphql',
  // apiUrl: 'https://vaisakh-82.twl-workspace.techwarelab.com/graphql',

  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: "AIzaSyCZdX5boMSUjsEMWLXcAcEYEttLGMWzVqg",
    authDomain: "lpa-energy-group.firebaseapp.com",
    databaseURL: "https://lpa-energy-group-default-rtdb.firebaseio.com",
    projectId: "lpa-energy-group",
    storageBucket: "gs://lpa-energy-group.appspot.com",
    messagingSenderId: "483374438736",
    appId: "1:483374438736:web:a54dcb86589ef2602ab455",
    measurementId: "G-SF2Q85TKXX"
  }
};
