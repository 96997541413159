import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  isSelect: String;
  userType: String;

  constructor() { this.getUserDetails() }


  setHeader(isSelect: String): void {
    this.isSelect = isSelect
  }

  getUserDetails(): void {
    const detailsString = localStorage.getItem("details")
    if (detailsString) {
      const userDetails = JSON.parse(detailsString);
      this.userType = userDetails?.roleType?.trim().toLowerCase() 
    }
  }
}
